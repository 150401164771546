import styles from "./index.module.scss";
import classNames from "classnames";
import Avatar from "../../../../components/Avatars";
import deployments from "../../../../utils/web3/deployments";
import { useAccount, useNetwork } from "wagmi";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import { CssButton } from "../../../../components/Buttons";
import { useState, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { ContractsContext } from "../../../../providers/ContractsProvider";
import { fetchNFTs, getBalance, getCurrentPhase } from "../../../../utils/web3";
import PenguinSelect from "../../../../components/Web3/Portal/PenguinSelect";
import { withPromiseToast } from "../../../../utils";
import Modal from "../../../../components/Web3/Modal";
import TabWrapper from "../../../../components/Web3/PolarPeaks/TabWrapper";
import Indicator from "../../../../components/Web3/PolarPeaks/Indicator";
import StatusChecker from "../../../../components/Web3/PolarPeaks/StatusChecker";

const snowflakes = [];
for (let i = 0; i < 50; i++) {
  snowflakes.push(<div key={i} className={styles.snowflake}></div>);
}

const confettiColors = ["#ff4e00", "#007bff", "#33cabb", "#ffcd00", "#e83e8c"];
const confettiElements = [];
for (let i = 0; i < 200; i++) {
  confettiElements.push(
    <div
      key={i}
      className={styles.confetti}
      style={{
        background:
          confettiColors[Math.floor(Math.random() * confettiColors.length)],
        animationDuration: `${3 + Math.random()}s`,
        animationDelay: `-${Math.random() * 2}s`,
      }}
    ></div>,
  );
}

const Rescue = () => {
  const contracts = useContext(ContractsContext);
  const { isConnected, address } = useAccount();
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();
  const { chain } = useNetwork();
  const [tab, setTab] = useState(0);
  const bridgeLimit = 25;
  const [selectedCPsAmount, setSelectedCPsAmount] = useState(0);

  const [state, setState] = useImmer<{
    loadedAccount: string | null;
    loaded: boolean;
    cozyPenguinsBalance: number;
    woofyBalance: number;
    cozyPenguinsReady: number[];
    cozyPenguinsIneligible: number[];
    woofys: number[];
    selectedCozyPenguins: boolean[];
    totalRescuedWoofies: number;
    page: string;
    currentlyRescued: number[];
    phase: number;
  }>({
    loadedAccount: null,
    loaded: false,
    cozyPenguinsBalance: 0,
    woofyBalance: 0,
    cozyPenguinsReady: [],
    cozyPenguinsIneligible: [],
    woofys: [],
    selectedCozyPenguins: [],
    totalRescuedWoofies: 0,
    page: "",
    currentlyRescued: [],
    phase: 0,
  });

  const loadNFTs = async () => {
    const cozyPenguins = await fetchNFTs(
      address,
      contracts.cozyPenguinTraveler,
    );
    const woofys = await fetchNFTs(address, contracts.woofy);

    const getWoofiesClaimedByCozyTokens = async (): Promise<boolean[]> => {
      return await contracts.woofy.woofiesClaimedByCozyTokens(cozyPenguins);
    };

    const woofiesClaimedByCozyTokens = await getWoofiesClaimedByCozyTokens();

    const cozyPenguinsIneligible = cozyPenguins.filter(
      (_, i) => Array.from(woofiesClaimedByCozyTokens)[i],
    );
    const cozyPenguinsReadyToRescue = cozyPenguins.filter(
      (_, i) => !Array.from(woofiesClaimedByCozyTokens)[i],
    );

    setState((state) => {
      state.cozyPenguinsBalance = cozyPenguins.length;
      state.cozyPenguinsReady = cozyPenguinsReadyToRescue;
      state.cozyPenguinsIneligible = cozyPenguinsIneligible;
      state.woofyBalance = woofys.length;
      state.woofys = woofys;
      state.selectedCozyPenguins = cozyPenguinsReadyToRescue.map(() => false);
    });
  };

  useEffect(() => {
    const initialize = async () => {
      const getTotalRescuedWoofies = async (): Promise<bigint> => {
        return await contracts.woofy.tokensClaimed();
      };
      const totalRescuedWoofies = await getTotalRescuedWoofies();
      const woofyBalance = parseInt(
        (await getBalance(address, contracts.woofy)).toString(),
      );
      const cozyPenguinsBalance = parseInt(
        (await getBalance(address, contracts.cozyPenguinTraveler)).toString(),
      );
      const currentPhase = parseInt(
        (await getCurrentPhase(contracts.woofy)).toString(),
      );

      setState((state) => {
        state.loaded = true;
        state.woofyBalance = woofyBalance;
        state.cozyPenguinsBalance = cozyPenguinsBalance;
        state.loadedAccount = address;
        state.phase = currentPhase;
        state.totalRescuedWoofies = Number(totalRescuedWoofies);
      });

      if (cozyPenguinsBalance > 0 || woofyBalance > 0) {
        loadNFTs();
      }
    };

    if (contracts.loaded && isConnected) {
      if (
        (chain?.id === 43114 && deployments.environmentName === "production") ||
        (chain?.id === 43113 && deployments.environmentName === "staging")
      ) {
        initialize();
      }
    }
  }, [
    contracts.loaded,
    address,
    chain,
    isConnected,
    state.woofyBalance,
    state.cozyPenguinsBalance,
  ]);

  useEffect(() => {
    setState((state) => {
      state.loaded = false;
      state.loadedAccount = "";
      state.cozyPenguinsReady = [];
      state.cozyPenguinsIneligible = [];
      state.woofys = [];
      state.selectedCozyPenguins = [];
    });
  }, [address]);

  useEffect(() => {
    setSelectedCPsAmount(
      state.cozyPenguinsReady.filter((_, i) => state.selectedCozyPenguins[i])
        .length,
    );
  }, [state.selectedCozyPenguins]);

  const isRightChain = () => {
    if (
      (chain?.id === 43114 && deployments.environmentName === "production") ||
      (chain?.id === 43113 && deployments.environmentName === "staging")
    ) {
      return true;
    }
    return false;
  };

  const rescueWoofies = async () => {
    const result = await withPromiseToast(async () => {
      setState((state) => {
        state.page = "loading";
      });
      const selectedTokenIds = state.cozyPenguinsReady
        .filter((_, i) => state.selectedCozyPenguins[i])
        .map((cozyPenguin) => cozyPenguin);

      const claimTx = await contracts.woofy.claim(selectedTokenIds);
      await claimTx.wait().then(() => {
        loadNFTs();
      });
    }).finally(() => {
      setState((state) => {
        state.currentlyRescued = state.cozyPenguinsReady.filter(
          (_, i) => state.selectedCozyPenguins[i],
        );
        state.page = "confirmation";
      });
    });
    if (result === "Something bad happened") {
      setState((state) => {
        state.page = "error";
      });
    }
  };

  const connectWallet = (
    <>
      <Avatar
        img="/assets/images/region/polarpeaks/rescue/capsule.png"
        className={styles.capsule}
      />
      <CssButton
        onClick={
          !isConnected
            ? openConnectModal
            : !isRightChain()
            ? openChainModal
            : null
        }
        text={
          !isConnected
            ? `Connect Wallet`
            : !isRightChain()
            ? `Change Chain`
            : null
        }
        rounded={false}
        fontSize="2rem"
        color="#42e6e8"
        className={styles.button}
        shadowSize="4px"
      />
    </>
  );

  const tabs = (
    <div className={styles.signsGrid}>
      <div
        className={classNames(styles.imageSign, styles.ready)}
        onClick={() => setTab(1)}
      >
        <div className={styles.text}>Cozys ready to rescue</div>
      </div>
      <div
        className={classNames(styles.imageSign, styles.rescued)}
        onClick={() => setTab(2)}
      >
        <div className={styles.text} style={{ right: "2%" }}>
          Cozys who rescued
        </div>
      </div>
      <div
        className={classNames(styles.imageSign, styles.checker)}
        onClick={() => setTab(3)}
      >
        <div className={styles.text}>Check cozy eligibility</div>
      </div>
    </div>
  );

  const ready = (
    <>
      <PenguinSelect
        title="polarpeaksRescueReady"
        balance={state.cozyPenguinsBalance}
        cozyPenguins={state.cozyPenguinsReady}
        loaded={
          state.cozyPenguinsReady.length +
            state.cozyPenguinsIneligible.length ===
          state.cozyPenguinsBalance
        }
        selected={state.selectedCozyPenguins}
        bridgeLimit={bridgeLimit}
        toggleSelected={(i) => {
          if (state.phase !== 0) {
            selectedCPsAmount < bridgeLimit
              ? setState((state) => {
                  state.selectedCozyPenguins[i] =
                    !state.selectedCozyPenguins[i];
                })
              : setState((state) => {
                  state.selectedCozyPenguins[i] = false;
                });
          }
        }}
        selectedAmount={selectedCPsAmount}
        hideBackBtn={true}
      />
      <CssButton
        onClick={rescueWoofies}
        text={state.phase === 0 ? "not open yet" : "rescue"}
        rounded={false}
        color="#42e6e8"
        className={state.phase === 0 ? styles.smallButton : styles.button}
        disabled={selectedCPsAmount === 0 || state.phase === 0}
        shadowSize={state.phase === 0 ? "3px" : "4px"}
      />
    </>
  );

  const rescued = (
    <PenguinSelect
      title="polarpeaksRescueIneligible"
      balance={state.cozyPenguinsBalance}
      cozyPenguins={state.cozyPenguinsIneligible}
      loaded={
        state.cozyPenguinsReady.length + state.cozyPenguinsIneligible.length ===
        state.cozyPenguinsBalance
      }
      selected={state.cozyPenguinsIneligible.map(() => false)}
      bridgeLimit={bridgeLimit}
      toggleSelected={() => {}}
      selectedAmount={selectedCPsAmount}
      hideBackBtn={true}
    />
  );

  const check = <StatusChecker title="cozy penguins" />;

  const content =
    !isConnected || !isRightChain() ? (
      connectWallet
    ) : !tab ? (
      tabs
    ) : (
      <TabWrapper
        title="rescue"
        children={tab === 1 ? ready : tab === 2 ? rescued : check}
        tab={tab}
        setTab={setTab}
      />
    );

  return (
    <>
      <div className={styles.root}>
        <div className={styles.contents}>
          <div></div>
          <div className={styles.centerContent}>
            <div className={styles.bannerWrapper}>
              <Avatar
                img="/assets/images/region/polarpeaks/rescue/banner.png"
                className={styles.banner}
              />
            </div>
            {content}
          </div>
          <div
            className={styles.rightContent}
            style={
              !isConnected || !isRightChain
                ? { gridTemplateRows: "none", paddingTop: "0rem" }
                : null
            }
          >
            <div className={classNames(styles.textSign)}>
              <img
                className={classNames(styles.sign)}
                src="/assets/images/region/polarpeaks/rescue/panel_text.png"
              />
              <div className={styles.text}>
                {tab === 1 || tab === 2 ? (
                  <>
                    - Each Cozy can rescue a woofy only once, even if owners
                    change. <br />
                    <br /> - {bridgeLimit} max each time.
                  </>
                ) : (
                  <>
                    {" "}
                    The woofys are trapped under the ice. You need a Cozy
                    Penguin to dig them out.
                  </>
                )}
              </div>
            </div>
            {isConnected && isRightChain && (
              <div className={styles.infoWrapper}>
                <Indicator
                  title={tab === 1 ? "selected" : "woofys rescued"}
                  selectedAmount={selectedCPsAmount}
                  selectedLimit={bridgeLimit}
                  totalWoofys={state.totalRescuedWoofies}
                  maxWoofys={3333}
                  myWoofies={state.woofyBalance}
                />
                <Indicator title="my woofies" myWoofies={state.woofyBalance} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.bg}> </div>
        <Avatar
          img="/assets/images/region/polarpeaks/portal/snow_b.png"
          className={styles.snowbehind}
        />
        <Avatar
          img="/assets/images/home/1/snow_f.png"
          className={styles.snowfront}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/rescue/left.png"
          className={styles.left}
        />
        <Avatar
          img="/assets/images/region/polarpeaks/rescue/right.png"
          className={styles.right}
        />
        {snowflakes}
      </div>
      <Modal
        title="rescue"
        page={state.page}
        selectedAmount={selectedCPsAmount}
        items={state.currentlyRescued}
        onClose={() => {
          setState((state) => {
            state.page = "";
            state.currentlyRescued = [];
          });
        }}
      />
      {state.page === "confirmation" && confettiElements}
      {state.page === "" ? null : (
        <Avatar
          img="/assets/images/region/polarpeaks/rescue.png"
          className={classNames(styles.modalRight)}
        />
      )}
    </>
  );
};

export default Rescue;
