import { toastError } from "../index";
import { MockCozyPenguin as CozyPenguinContract } from "../../../src/typechain";
import { CozyPenguinTraveler } from "../../../src/typechain";
import { Woofy } from "../../typechain_woofy";
import { BigNumberish } from "ethers";
import { camelCase, isArray, isObject, transform } from "lodash";

export type WoofyMetadata = {
  name: string;
  image: string;
  pack: string;
  isPackLeader: boolean;
  attributes: Trait[];
};

export type Trait = {
  traitType: string;
  value: string;
};

export const camelize = (obj: any) =>
  transform(obj, (result: any, value: unknown, key: string, target: any) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    result[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const buildCozyPenguinTokenImage = (id: number) => {
  return `https://s3.us-west-2.amazonaws.com/static.cozyverse.xyz/partner_token_images/CozyPenguins/${String(
    id,
  )}`;
};

export const buildCozyPenguinExplorerTokenImage = (id: number) => {
  return `https://static.cozyverse.xyz/cozy-penguin-explorers/images/${String(
    id,
  ).padStart(5, "0")}.png`;
};
export const getWoofyMetadataId = async (
  contract: Woofy,
  woofyId: BigNumberish,
): Promise<number> => {
  return Number(await contract.metadataId(woofyId));
};

export const buildWoofyTokenImage = (id: number) => {
  return `https://static.cozyverse.xyz/woofys/images/${String(id).padStart(
    5,
    "0",
  )}.png`;
};

export const getWoofyMetadata = async (
  contract: Woofy,
  woofyId: number,
): Promise<WoofyMetadata> => {
  const id = await getWoofyMetadataId(contract, woofyId);

  const resp = await fetch(
    `https://static.cozyverse.xyz/woofys/metadata/${id}.json`,
  );
  const base = camelize(await resp.json()) as WoofyMetadata;

  const packLeaderTrait = base.attributes
    .filter((attr) => attr.traitType.toLowerCase() === "pack leader")
    .pop();
  base.isPackLeader = packLeaderTrait !== undefined;
  base.pack =
    base.attributes
      .filter((attr) => attr.traitType.toLowerCase() === "pack")
      .pop()?.value || "Unknown";
  base.attributes = base.isPackLeader
    ? [packLeaderTrait]
    : base.attributes.filter((attr) => attr.traitType.toLowerCase() !== "pack");
  return base;
};

export const getBalance = async (
  address,
  contract: CozyPenguinContract | CozyPenguinTraveler | Woofy,
): Promise<bigint> => {
  return await contract.balanceOf(address);
};

export const getCurrentPhase = async (contract: Woofy): Promise<bigint> => {
  return await contract.currentPhase();
};

export const fetchNFTs = async (
  address,
  contract: CozyPenguinContract | CozyPenguinTraveler | Woofy,
): Promise<number[]> => {
  const balance = await getBalance(address, contract);

  const tokenIds = await Promise.all(
    Array.from({ length: parseInt(balance.toString()) }).map(
      async (_, i: number) =>
        (await contract.tokenOfOwnerByIndex(address, i)).toString(),
    ),
  ).catch(() => {
    toastError("Unable to load Cozy Penguins");
    return [] as string[];
  });

  return tokenIds.map((tokenId) => parseInt(tokenId));
};
