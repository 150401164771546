import styles from "./index.module.scss";
import Avatar from "../../../components/Avatars";
import { Bold } from "../../Card";
import { CssButton } from "../../Buttons";
import { useContext, useEffect, useState } from "react";
import { CollectionsCozyPenguins } from "../../../constants/links";
import { useImmer } from "use-immer";
import { ContractsContext } from "../../../providers/ContractsProvider";
import { fetchNFTs, getBalance } from "../../../utils/web3";
import PenguinSelect from "./PenguinSelect";
import TravelModal from "./TravelModal";
import WarpGate from "./WarpGate";
import TextSign from "./TextSign";
import TransactionHistory from "./TransactionHistory";
import TravelTransactionsProvider from "../../../providers/TravelTransactionsProvider";
import { useAccount, useNetwork } from "wagmi";
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit";
import classNames from "classnames";
import deployments from "../../../utils/web3/deployments";
import { MockCozyPenguin as CozyPenguinContract } from "../../../typechain";
import { CozyPenguinTraveler } from "../../../typechain";

const Penguins = () => {
  return (
    <Avatar img="/assets/images/region/cozyreef/cr.png" className={styles.cr} />
  );
};

const Portal = ({ region }) => {
  const contracts = useContext(ContractsContext);
  const { isConnected, address } = useAccount();
  const { openChainModal } = useChainModal();
  const { openConnectModal } = useConnectModal();
  const { chain } = useNetwork();
  const [selectedAmount, setSelectedAmount] = useState(0);
  const bridgeLimit = 25;

  const [state, setState] = useImmer<{
    loadedAccount: string | null;
    loaded: boolean;
    modal: {
      open: boolean;
    };
    balance: number;
    cozyPenguins: number[];
    selected: boolean[];
    fee: string;
  }>({
    loadedAccount: null,
    loaded: false,
    modal: {
      open: false,
    },
    balance: 0,
    cozyPenguins: [],
    selected: [],
    fee: "",
  });

  const loadCozyPenguins = async (
    contract: CozyPenguinTraveler | CozyPenguinContract,
  ) => {
    const cozyPenguins = await fetchNFTs(address, contract);
    setState((state) => {
      state.balance = cozyPenguins.length;
      state.cozyPenguins = cozyPenguins;
      state.selected = cozyPenguins.map(() => false);
    });
  };

  useEffect(() => {
    const initialize = async (
      contract: CozyPenguinTraveler | CozyPenguinContract,
    ) => {
      const balance = parseInt(
        (await getBalance(address, contract)).toString(),
      );
      setState((state) => {
        state.loaded = true;
        state.balance = balance;
        state.loadedAccount = address;
      });

      if (balance > 0) {
        loadCozyPenguins(contract);
      }
    };
    if (contracts.loaded && isConnected) {
      if (
        (chain?.id === 1 && deployments.environmentName === "production") ||
        (chain?.id === 11155111 && deployments.environmentName === "staging")
      ) {
        initialize(contracts.cozyPenguin);
      } else if (
        (chain?.id === 43114 && deployments.environmentName === "production") ||
        (chain?.id === 43113 && deployments.environmentName === "staging")
      ) {
        initialize(contracts.cozyPenguinTraveler);
      }
    }
  }, [contracts.loaded, address, chain, isConnected, state.balance]);

  useEffect(() => {
    setState((state) => {
      state.loaded = false;
      state.loadedAccount = "";
      state.cozyPenguins = [];
      state.selected = [];
    });
  }, [address]);

  useEffect(() => {
    if (state.loadedAccount && address !== state.loadedAccount) {
      setState((state) => {
        state.loaded = false;
        state.loadedAccount = null;
      });
    }
  }, [address]);

  useEffect(() => {
    setSelectedAmount(
      state.cozyPenguins.filter((_, i) => state.selected[i]).length,
    );
  }, [state.selected]);

  const connectWalletTextSign = (
    <div className={styles.infoGroup}>
      <div className={styles.group}>
        <TextSign
          img={
            <img
              className={classNames(styles.sign, styles.penguinSign)}
              src="/assets/images/region/cozyreef/portal/speechbubble_2.png"
            />
          }
          style={{
            bottom: "30%",
            transform: "translateY(40%)",
          }}
        >
          You need to have a <Bold>Cozy Penguin</Bold> and connect your wallet
          to jump in the portal
        </TextSign>
        <CssButton
          onClick={openConnectModal}
          text="Connect Wallet"
          rounded={false}
          fontSize="2rem"
          color="#f19a4a"
          className={styles.nowrap}
          shadowSize="4px"
        />
      </div>
      <Penguins />
    </div>
  );

  const changeChainsTextSign = (
    <div className={styles.infoGroup}>
      <div className={styles.group}>
        <TextSign
          img={
            <img
              className={classNames(styles.sign, styles.penguinSign)}
              src="/assets/images/region/cozyreef/portal/speechbubble_2.png"
            />
          }
          style={{
            bottom: "30%",
            transform: "translateY(40%)",
          }}
        >
          You need to{" "}
          <Bold>
            switch to {region === "cozyreef" ? "Ethereum" : "Avalanche"}
          </Bold>{" "}
          chain
        </TextSign>

        <CssButton
          onClick={openChainModal}
          text="Change chain"
          rounded={false}
          fontSize="2rem"
          color="#f19a4a"
          className={styles.nowrap}
          shadowSize="4px"
        />
      </div>
      <Penguins />
    </div>
  );

  const noPenguinsTextSign = (
    <div className={styles.infoGroup}>
      <div className={styles.group}>
        <TextSign
          img={
            <img
              className={classNames(styles.sign, styles.penguinSign)}
              src="/assets/images/region/cozyreef/portal/speechbubble_nocozy.png"
            />
          }
          style={{
            bottom: "30%",
            transform: "translateY(25%)",
          }}
        >
          You <Bold color="#c65b54">don't</Bold> have a Cozy Penguin in your
          wallet
        </TextSign>
        <CssButton
          onClick={() => {
            window.location.assign(CollectionsCozyPenguins);
          }}
          text="Learn More"
          rounded={false}
          fontSize="2rem"
          color="#f19a4a"
          shadowSize="4px"
        />
      </div>
      <Penguins />
    </div>
  );

  const onModalClose = () => {
    setState((state) => {
      state.modal.open = false;
    });
  };

  const penguinSelect = (
    <div className={styles.lhs}>
      <PenguinSelect
        title={region}
        balance={state.balance}
        cozyPenguins={state.cozyPenguins}
        loaded={state.cozyPenguins.length === state.balance}
        selected={state.selected}
        bridgeLimit={bridgeLimit}
        toggleSelected={(i) => {
          selectedAmount < bridgeLimit
            ? setState((state) => {
                state.selected[i] = !state.selected[i];
              })
            : setState((state) => {
                state.selected[i] = false;
              });
        }}
        selectedAmount={selectedAmount}
      />
      <TransactionHistory />
    </div>
  );

  const isRightChain = () => {
    if (
      region === "cozyreef" &&
      ((chain?.id === 1 && deployments.environmentName === "production") ||
        (chain?.id === 11155111 && deployments.environmentName === "staging"))
    ) {
      return true;
    } else if (
      region === "polarpeaks" &&
      ((chain?.id === 43114 && deployments.environmentName === "production") ||
        (chain?.id === 43113 && deployments.environmentName === "staging"))
    ) {
      return true;
    }
    return false;
  };

  const textCard = !isConnected
    ? connectWalletTextSign
    : !isRightChain()
    ? changeChainsTextSign
    : state.loaded
    ? state.balance === 0
      ? noPenguinsTextSign
      : penguinSelect
    : null;

  return (
    <TravelTransactionsProvider>
      <div className={styles.penguinsContainer}>{textCard}</div>
      <div className={styles.warpGateContainer}>
        <WarpGate
          region={region}
          cozyPenguins={state.cozyPenguins}
          selected={state.selected}
          loaded={state.loaded}
          setModalOpen={() => {
            setState((state) => {
              state.modal.open = true;
            });
          }}
          setFee={(fee) => {
            setState((state) => {
              state.fee = fee;
            });
          }}
        />
      </div>
      <TravelModal
        region={region}
        open={state.modal.open}
        cozyPenguins={state.cozyPenguins}
        selected={state.selected}
        onClose={onModalClose}
        onSuccessfulTravel={async () => {
          if (region === "cozyreef") {
            loadCozyPenguins(contracts.cozyPenguin);
          } else if (region === "polarpeaks") {
            loadCozyPenguins(contracts.cozyPenguinTraveler);
          }
        }}
        fee={state.fee}
      />
      {region === "polarpeaks" && (
        <Avatar
          img="/assets/images/region/polarpeaks/portal/glow.png"
          className={styles.glow}
        />
      )}
    </TravelTransactionsProvider>
  );
};

export default Portal;
