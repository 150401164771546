import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { ReactNode, useContext } from "react";
import { ImageButton } from "../Buttons";
import { useImmer } from "use-immer";
import deployments from "utils/web3/deployments";
import React, { useRef, useEffect } from "react";
import {
  CollectionsBase,
  CollectionsCozyPenguins,
  CollectionsWoofys,
  GamesBase,
  GamesLandslide,
  RegionBase,
  RegionCozyReef,
  RegionPolarPeaks,
  Story,
} from "../../constants/links";
import { isMobile } from "react-device-detect";
import { CustomConnectButton } from "../Web3/ConnectButton";

const Tab = ({ children, active, display }) => {
  return display ? (
    <div
      className={classNames(styles.tab, {
        [styles.active]: active,
      })}
    >
      {children}
    </div>
  ) : null;
};

const SingleTab = ({ title, url, display }) => {
  return (
    <Tab active={window.location.pathname === url} display={display}>
      <Link to={url} reloadDocument={true}>
        {title}
      </Link>
    </Tab>
  );
};

const DropdownTab = ({
  title,
  children,
  isActive,
  display = true,
}: {
  title: string;
  children: ReactNode;
  isActive: () => boolean;
  display?: boolean;
}) => {
  return (
    <Tab active={isActive()} display={display}>
      {title}
      <div className={styles.dropdownWrapper}>
        <div className={styles.dropdown}>{children}</div>
      </div>
    </Tab>
  );
};

const DropdownItem = ({ title, url, close }) => {
  return (
    <div
      className={classNames(styles.item, {
        [styles.active]: window.location.pathname === url,
      })}
    >
      <Link to={url} reloadDocument={true} onClick={close}>
        {title}
      </Link>
    </div>
  );
};

const Nav = () => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !state.showNav &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setState((state) => {
          state.showNav = false;
        });
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [state, setState] = useImmer<{
    showNav: boolean;
    showModal: boolean;
  }>({
    showNav: false,
    showModal: false,
  });

  return (
    <div className={styles.root}>
      <div className={styles.mobile}>
        <ImageButton
          className={styles.imageButton}
          img="/assets/images/games/shared/icon_expand.png"
          onMouseDown={(e) => {
            setState((state) => {
              state.showNav = !state.showNav;
            });
            e.stopPropagation();
          }}
        />
      </div>
      <div className={styles.logo}>
        <Link to="/" reloadDocument={true}>
          <img src="/assets/images/cozylabs.png" />
        </Link>
      </div>
      <div
        ref={ref}
        className={classNames(styles.tabs, {
          [styles.hide]: !state.showNav,
          [styles.mobileTabs]: isMobile,
        })}
      >
        <SingleTab title="Cozyverse" url="/" display={isMobile} />
        <DropdownTab
          title="Games"
          isActive={() => {
            return window.location.pathname.includes(GamesBase);
          }}
        >
          <DropdownItem
            title="Landslide"
            url={GamesLandslide}
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
          <DropdownItem
            title="Waddle Wars"
            url="/game/waddle-wars"
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
          {deployments.environmentName === "staging" && (
            <>
              {" "}
              <DropdownItem
                title="Cozy Verse"
                url="/game/cozy-verse-demo"
                close={() => {
                  setState((state) => {
                    state.showNav = false;
                  });
                }}
              />
              <DropdownItem
                title="Slingshot Sailors"
                url="/game/slingshot-sailors-demo"
                close={() => {
                  setState((state) => {
                    state.showNav = false;
                  });
                }}
              />
            </>
          )}
        </DropdownTab>
        <DropdownTab
          title="Collections"
          isActive={() => {
            return window.location.pathname.includes(CollectionsBase);
          }}
        >
          <DropdownItem
            title="Cozy Penguins"
            url={CollectionsCozyPenguins}
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
          <DropdownItem
            title="Woofys"
            url={CollectionsWoofys}
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
        </DropdownTab>
        <DropdownTab
          title="Regions"
          isActive={() => {
            return window.location.pathname.includes(RegionBase);
          }}
        >
          <DropdownItem
            title="Cozy Reef"
            url={RegionCozyReef}
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
          <DropdownItem
            title="Polar Peaks"
            url={RegionPolarPeaks}
            close={() => {
              setState((state) => {
                state.showNav = false;
              });
            }}
          />
        </DropdownTab>
        <SingleTab title="Story" url={Story} display />
      </div>
      {window.location.pathname.match(/(portal|campsite|rescue|demo)/) && (
        <CustomConnectButton />
      )}
    </div>
  );
};

const AddNavToPage = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Nav />
      </div>
      {children}
    </div>
  );
};

export { AddNavToPage };
