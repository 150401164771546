import Section from "../../../components/Section";
import Avatar from "../../../components/Avatars";
import {
  AppStoreButton,
  AppStoreType,
  Description,
  Game,
} from "../../../components/GamePage";
import { ReactNode } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Bold } from "../../../components/Card";

const Subtitle = "Community Game";

const Buttons = [
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href=""
    appStore={AppStoreType.apple}
    active={false}
  />,
  <AppStoreButton
    className={styles.appStoreMobileButtons}
    href=""
    appStore={AppStoreType.android}
    active={false}
  />,
];

const SlingshotSailorsGame = ({ buttons }: { buttons: ReactNode[] }) => {
  const unityConfig = {
    loaderUrl:
      "/assets/build/slingshotsailors/Build/SlingshotSailors.loader.js",
    dataUrl: "/assets/build/slingshotsailors/Build/SlingshotSailors.data",
    frameworkUrl:
      "/assets/build/slingshotsailors/Build/SlingshotSailors.framework.js",
    codeUrl: "/assets/build/slingshotsailors/Build/SlingshotSailors.wasm",
  };

  return (
    <Game
      unityConfig={unityConfig}
      titleSrc={"/assets/images/games/slingshot_sailors/logo.png"}
      subtitle={Subtitle}
      buttons={buttons}
      thirdweb={true}
    />
  );
};

const SlingshotSailorsHero = ({
  className,
  playable = true,
}: {
  className?;
  playable?: boolean;
}) => {
  return (
    <Section className={classNames(styles.hero, className)}>
      <Helmet>
        <title> Slingshot Sailors | Play </title>
      </Helmet>
      <SlingshotSailorsGame buttons={Buttons} />
    </Section>
  );
};

const SlingshotSailorsPage = () => {
  return (
    <div className={styles.root}>
      <SlingshotSailorsHero playable={true} />
      <Section className={styles.about}>
        <Description>
          <p>
            This is a <Bold>turn-based community game</Bold>. Every player's
            interaction affects other player’s decisions. There is a storm
            approaching and you have to get to the city to be safe from the
            storm. If you fall too far behind you get lost in the storm and are
            eliminated.
          </p>
        </Description>

        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            left: "-15rem",
            bottom: "-10rem",
          }}
        />
        <Avatar
          img="/assets/images/games/shared/ducks.png"
          hideMobile
          style={{
            right: "-15rem",
            top: "-10rem",
          }}
        />
      </Section>
    </div>
  );
};

export { SlingshotSailorsPage, SlingshotSailorsHero };
